import { Grid, Container, Typography, Stack } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  fontSize: '16px',
  textAlign: 'left',
  color: 'black',
}));

function About() {
  return (
    <Container sx={{display:'flex', justifyContent: 'center'}}>
        <Grid item xs={12} md={8} sx={{p:1,'& .markdown': { py: 3,},}}>
            <Typography variant="h5" gutterBottom sx={{textAlign: 'center', fontWeight: 700}}>
                About Me...
            </Typography>

            <Stack spacing={2}>
                <Item>
                <Typography variant="h6" sx={{textAlign: 'left', fontWeight: 'bold'}}>
                    I make YouTube videos
                </Typography>

                <Typography variant="body" >
                  <p><em><strong>EliteAri</strong></em> is my main channel for scripted videos, where I try to release a new video every two weeks. I don't have any real philosophy behind the content of my videos- they can be about anything I find interesting (albeit so far that seems to be mostly anime/manga analysis).</p>
                  <p><em><strong>OrdinaryAri</strong></em> is for my unscripted content. This includes a variety of solo and collaborative videos. At the end of each month, I release a digest video on OrdinaryAri where I discuss and rate all the media I've consumed during that month. See the <a href="/suggestions">Digest</a> page for a list of media that's been suggested to me so far. I also <em><strong>livestream</strong></em> regularly on OrdinaryAri, where I mostly just talk about topics I'm passioante about, or react to YouTube videos.</p>
                </Typography>
                </Item>

                <Item>
                <Typography variant="h6" sx={{textAlign: 'left', fontWeight: 'bold'}}>
                    I like Anime & Manga
                </Typography>

                <Typography variant="body" >
                    <p>Anime and Manga are incredibly creative mediums that allow for stories in which almost anything you can imagine can happen. At the moment, I'm probably most involved inthe Attack on Titan, Chainsaw Man, and Mushoku Tensei fandoms.</p>
                    <p>If you want to know what I think about the anime I've seen, check out my <a target="_blank" rel="noopener noreferrer" href="https://myanimelist.net/profile/EliteAri">MAL</a>. If you want my extremely detailed thoughts, check out my <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/playlist?list=PLyw71k0DqgI5L_OGFJl8d4N_hpB02qmnd">'Reflecting on Every Anime I've Seen'</a> video series on EliteAri</p>
                </Typography>
                </Item>

                <Item>
                <Typography variant="h6" sx={{textAlign: 'left', fontWeight: 'bold'}}>
                    I am a Computer Science PhD Student researching AI
                </Typography>

                <Typography variant="body" >
                    <p>In my final years of high school I had a passion for science and a passion for technology. I was interested in the human brain, but I was also interested in computers. So rather than choose to persue a career in only one of these fields, I decided to persue both of them. This ended up with me getting two undergraduate degrees: <em>Bachelor of Science in Neuroscience</em> and <em>Bachelor of Science in Computer Science (Artificial Intelligence)</em>. This took 4 years, followed by an additional year for an extra <em>Bachelor of Science (Honours) in Computer Science</em>.</p>
                    <p>Having enjoyed my research during my honours year, I considered doing a PhD, but what convinced me to go through with it in the end was an offer to work in collaboration with Harvard as part of my PhD project. After much delay due to covid, this collaboration ended up happening in 2023 where I got to spend 6-months with an amazing research group in Boston.</p>
                    <p>My research is about AI, or more specifically, AI in the domain of computer vision. It's been wild seeing AI become such a mainstream topic of debate over the past year.</p>
                </Typography>
                </Item>


                <Item>
                <Typography variant="h6" sx={{textAlign: 'left', fontWeight: 'bold'}}>
                    I like Virtual Reality
                </Typography>

                <Typography variant="body" >
                    <p>VR is <em>obviously</em> the future of communication. It will eventually provide a true feeling of presence with other people without having to physically be in close proximity to them. It's the natrual next step in communication technology. Just imagine the endless use cases for education, recreation, family, relationships, etc. Imagine truly feeling as though you are having a games night at your house, while your friends are countries away. Imagine truly feeling like you are at a live concert full of countless other people, without having to leave your house. Imagine recording and being able to relive moments in time. These are just a few use cases if you assume that a true feeling of <em>presence</em> in a space and with other people is able to be achieved.</p>
                    <p>VR is <em>almost certainly</em> the future of productivity. At the bare minimum, it will replace the need for physical monitors, which is disruptive enough in its own right. However, I imagine it could be the next form of remote work, making it feel like you are at a physical collaborative workplace with coworkers working at their desks, without having to ever leave your room.</p>
                    <p>No, I don't care much about VR for gaming. It's fun and all, but the amount of people using VR for communication and productivity will dwarf those using VR for gaming in the long term.</p>
                </Typography>
                </Item>

                <Item>
                <Typography variant="h6" sx={{textAlign: 'left', fontWeight: 'bold'}}>
                    Links
                </Typography>

                <Typography variant="body" >
                    <ul>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/@eliteari">YouTube</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://twitter.com/eliteari2">Twitter</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://myanimelist.net/profile/EliteAri">MyAnimeList</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://letterboxd.com/eliteari/">Letterboxd</a></li>
                        <li><a href="mailto: ari@eliteari.com">Email</a></li>
                    </ul>
                </Typography>
                </Item>
            </Stack>
        </Grid>
    </Container>
  );
}
 
export default About;
